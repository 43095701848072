<!-- @format -->

<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <img src="@/assets/images/logo/logo.png" alt="">
          <!-- <h2 class="brand-text text-primary ml-1">WOW</h2> -->
        </b-link>

        <b-card-title class="mb-1"> Welcome to WOW! 👋 </b-card-title>
        <!--        <b-card-text class="mb-2"> Please sign-in to your account and start the adventure </b-card-text>-->

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group label="Account" label-for="login-account">
              <b-form-input id="login-account" v-model="account" name="account" placeholder="Email or Phone Number" />
            </b-form-group>
            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="login-password" v-model="password_hash" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Password" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- check_code -->
            <!-- <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-check_code">Verify Code</label>
              </div>
              <validation-provider #default="{ errors }" name="Verify Code" vid="check_code" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="login-check_code" v-model="check_code" :state="errors.length > 0 ? false : null" name="login-check_code" placeholder="Verify Code！" style="margin-right: 5px" />
                  <img v-if="showCode" :src="IMGCODEURL" style="height: 36px; padding-left: 5px; border: 1px solid #ccc" @click="checkCodeChange">
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <!-- submit button -->
            <b-button variant="primary" type="submit" block> Login </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span />
          <b-link :to="{ name: 'auth-register' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

      </b-card>

      <div v-show="showAlert" class="error-alert">
        {{ errorMsg }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroupAddon, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      IMGCODEURL: process.env.VUE_APP_IMG_CODE_URL,
      src: null,
      showCode: true,
      status: '',
      password_hash: '',
      account: '',
      check_code: '',
      showAlert: false,
      errorMsg: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // this.checkCodeChange()
  },
  methods: {
    async checkCodeChange() {
      this.showCode = false
      await axiosIns.get('/login/getImgVerifyCode')
      this.showCode = true
      this.$forceUpdate() // 补足刷新
    },
    fasong() {},
    login() {
      this.errorMsg = ''
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              account: this.account,
              password_hash: this.password_hash,
              // check_code: this.check_code,
            })
            .then(response => {
              if (response.code == -1) {
                this.errorMsg = response.msg
                this.showAlert = true
                setTimeout(() => {
                  this.showAlert = false
                }, 3000)
                // this.$bvToast.toast(response.msg, {
                //   variant: 'danger',
                // })
                // this.checkCodeChange()
              } else {
                const userData = {
                  // id: 1,
                  fullName: response.data.real_name,
                  username: response.data.real_name,
                  avatar: response.data.avatar,
                  email: response.data.email,
                  role: response.data.role_name,
                  first_login: response.data.first_login,
                  ability: [
                    {
                      action: 'manage',
                      // subject: 'all',
                    },
                  ],
                }
                localStorage.setItem('first_login', response.data.first_login)
                localStorage.setItem('email', response.data.email)
                useJwt.setToken(response.data.token)
                // useJwt.setRefreshToken(response.data.token)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)
                window.location.reload()

                // this.$router.push({ path: userData.role == '会员' ? '/vip/home' : '/general/dashboard' })
              }
            })
            .catch(error => {
              console.log(error)
              // this.$refs.loginForm.setErrors(error.response.data.error)
              console.log('错误')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.check {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
}
.error-alert {
  position: fixed;
    width: 400px;
    height: 48px;
    left: calc(50% - 200px);
    top: calc(50% - 24px);
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
</style>
